body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html::-webkit-scrollbar{
  width: 0.4rem;
}

html::-webkit-scrollbar-thumb{
  background-color: #E66294;
  border-radius: 5rem;
}

html::-webkit-scrollbar-track{
  background-color: rgb(237, 237, 237);
}