.navigationMenuIcons{
    display: flex;
    ul{
        display: flex;
        list-style: none;
        li{
            margin-right: 20px;
            padding-top: 10px;
            a{
              
                svg{
                    width:20px;
                    height: 25px;
                    color: white;

                    &:hover{
                        color:black;
                    }
                }
            }
        }

    }
    button{
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: black;
    font-weight: bold;

    // &:hover{
    //     background-color: rgb(45, 45, 109);
    // }
    }
}


@media only screen and(max-width:600px){
    .navigationMenuIcons{
        button{
            display: none;
        }
    }
}

@media only screen and(max-width:390px){
.navigationMenuIcons{
    display: none;
}
}
