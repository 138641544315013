
.headerSection{
    display: flex;
    justify-content: space-between;
    padding: 0 80px;
    height:80px;
    width: 100%;
    background-color: transparent;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}

@media only screen and(min-width: 1200px){
.headerSection{
    width: 90%;
    margin: 0 auto;
    margin: 0 5%;
    height: 100px;
    // left: unset;
    // display: block;
}
};
    @media only screen and(max-width: 930px){
        .headerSection{
            // margin: 0 2px;
            padding: 0 30px;
        }
    }
    
    
    @media only screen and(max-width: 450px){
        .headerSection{
            // margin: 0 2px;
            padding: 0 10px;
        }
    }