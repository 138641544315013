.noNavigation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  font-size: 1.3rem;
// align-self: center;
// justify-self: center;
}

@media only screen and(max-width:650px) {
  .noNavigation {
      left: 0;
      transform: translateX(0);
      margin: 0 20px;
  }
}
