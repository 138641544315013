$primaryColor:#E66294;

.sideBar{

    button{
        position: absolute;
        top: 30px;
        right: 30px;
        outline: none;
        border: none;
        cursor: pointer;
        background-color: transparent; 
        svg{
        
    }

    &:hover{
        color: #E66294;
    }
    }
   
//   display: none;
  position: fixed;
  right: 0;
  top: 0;
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
//   transition: all 1s ease-in;
//   transform: translateX(-100vw);
  .navigationMenuItem{
    flex-direction: column;
    text-align: center;
    list-style: none;
    transform: translateY(-50%);
    p{
      // text-align: center;
      text-transform: uppercase;
      line-height: 40px;
      text-decoration: none;
      color: black;
      font-weight: bold;
      font-size: larger;
      cursor: pointer;

      &:hover{
          color: $primaryColor;
      }
    }
  }
  
}

.sideBarSocials{
// position: fixed;
//   transform: translateX(-100vw);
    ul{
    position: fixed;
    list-style: none;
    bottom: 30%;
    left: 50%;
    width: 40%;
    transform: translateX(-50%);

    z-index: 1001;
    // background-color: red;
    // color: red;
      display: flex;
//   flex-direction: row;
justify-content: space-between;
li{

    // padding-right: 40px;
    svg{
        width: 30px;
        height: 30px;
        color: black;


        &:hover{
            color: $primaryColor;
        }
    }
}
  
    }

}