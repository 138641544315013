$primaryColor:#E66294;

.logo{
    
font-weight: bold;
font-size: 1.5rem;
display: flex;
// row-gap: 10px;
column-gap: 15px;
a{
    cursor: pointer;
    text-decoration: none;
    color: black;
}
button{
display: none;
    svg{
        margin-top: 5px;
        // display: none;
    
    }
}

    // span{
    //     color: $primaryColor;
    // }
}

@media only screen and(max-width:1010px){
    .logo{
        // span{
        //     color: white;
        // }
    }
}

@media only screen and(max-width:930px){
.logo{
    button{
        display: block;
        outline: none;
        border: none;
        background-color: transparent;
        cursor: pointer;
        svg{
           
        }

        &:hover{
            color: $primaryColor;
        }
    }
}
}
@media only screen and(max-width:450px){
    .logo{
      font-size: 1.3rem;}
      a{
          padding-top: 3px;
      }
}