.imageGrid {
  padding: 50px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 13px;
  grid-auto-rows: minmax(200px, auto);
  // background-color: red;

  div {
    border-radius: 15px;
    transition: all 0.8s ease-in;

    &:hover{
        transform: scale(1.04);
    }
  }

  div:nth-of-type(1) {
    // background: url("http://localhost:3000/images/29copy.png") center center
    //   no-repeat;
    // background-size: cover;

    background-color: rgb(58, 180, 140);
    grid-row: 1/3;
  }
  div:nth-of-type(2) {
    // background: url("http://localhost:3000/images/11copy.png") center center
    //   no-repeat;
    // background-size: cover;
    background-color: rgb(40, 56, 9);
  }
  div:nth-of-type(3) {
    // background: url("http://localhost:3000/images/196copy.png") center center
    //   no-repeat;
    // background-size: cover;
    background-color: rgb(177, 48, 149);
  }
  div:nth-of-type(4) {
    // background: url("http://localhost:3000/images/1copy.png") center center
    //   no-repeat;
    // background-size: cover;
    background-color: rgb(87, 70, 136);
    grid-column: 1/3;
  }
}

@media only screen and(max-width:560px){
.imageGrid{
    grid-auto-rows: minmax(150px, auto);
}
}