$primaryColor:#E66294;

.navigationMenuItems {
  ul {
    display: flex;
    list-style: none;

    li {
        margin-right: 15px;
      p {
        text-decoration: none;
        color: black;
        padding: 7px;
        font-weight: bold;
        font-size: 17px;
        cursor: pointer;

        &:hover{
            color:$primaryColor;
        }

      }

    }
  }
}


@media only screen and(min-width:1200px) {
  .navigationMenuItems{
ul{
    li{
      p{
        font-size: 18px;
      }
    }
  }
}
  }

  @media only screen and(max-width: 1010px){
    .navigationMenuItems{
      ul{
        li{
          p{
&:hover{
        color: white;
          }
        }
      }
      
      }
    }
  }

  @media only screen and(max-width:930px){
///navigation side bar
.navigationMenuItems{
  display: none;
  // position: fixed;
  // right: 0;
  // top: 0;
  // background-color: white;
  // width: 100%;
  // height: 100vh;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // transform: translateX(-100vw);
  // ul{
  //   flex-direction: column;
  //   text-align: center;
  //   a{
 
  //     text-transform: uppercase;
  //     line-height: 40px;
  //   }
  // }
}
  }
  