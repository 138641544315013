$primaryColor: #e66294;

.wrapper {
  background-color: black;
  color: white;
}

.container {
  margin: 0 80px;
  width: auto;
  display: grid;
  // grid-template-columns: repeat(auto-fit,minmax(1500px,auto));
  grid-template-columns: 1fr 1fr;
}
.meetTheTeamSection {
  background-color: transparent;
  // padding: 80px 80px;
  display: flex;
  // grid-template-columns: 1fr 1fr 1fr;
  // column-gap: 10px;
  justify-content: space-around;
}
.meetTheTeamWrapper {
  margin: 50px 80px;
}

.joinTheMoranCommunity {
  background-color: #4c4c4c;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  button {
    outline: none;
    border: none;
    padding: 10px;
    cursor: pointer;
    background-color: white;
    border-radius: 40px;
    margin-top: 8px;
    
    a {
      text-decoration: none;
      font-weight: bold;
      color: black;
    }

    &:hover {
      background-color: $primaryColor;
      a {
        color: white;
      }
    }
  }

  p:nth-of-type(1) {
    font-size: 30px;
    color: white;
    font-weight: bold;
  }
  p:nth-of-type(2) {
    font-size: 16px;
    color: white;
    font-weight: bold;
    text-align: center;
  }
}


.roadMap{
  background-color: $primaryColor;
  color: white;
}

.footerSection {
  background-color: black;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}


.frequentlyAskedQuestions{
  padding: 40px;

  h1{
    text-align: center;
    margin-bottom: 20px;
  }
}




.cc {
  position: absolute;
  color: white;
  bottom: 5px;
  left: 5px;
}

.mintScreen{
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media only screen and(max-width:1110px){

.meetTheTeamWrapper{
  margin: 30px 30px;
}
}
@media only screen and(max-width:1000px){
.meetTheTeamSection{
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  row-gap: 30px;
}
}




@media only screen and(max-width:950px){
  .container{
    grid-template-columns: repeat(auto-fit,minmax(475px,auto));
    margin: 0 45px;
  }
  
  // .meetTheTeamWrapper{
    //   margin: 30px 50px;
    // }
  }
 
 @media only screen and(max-width:690px){
  .meetTheTeamSection{
   
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    
    
  }
  }

  @media only screen and(max-width:550px){
  .container{
    grid-template-columns: repeat(auto-fit,minmax(250px,auto));
    margin: 0 20px;
  }
  }


  @media only screen and(max-width:420px){
    .cc{
      font-size: small;
    }
    .frequentlyAskedQuestions{
      padding: 20px;
    }
    .joinTheMoranCommunity{
      p:nth-of-type(1){
        font-size: 20px;
      }
      p:nth-of-type(2){
        font-size: 14px;
      }
    }
  .container{
    grid-template-columns: repeat(auto-fit,minmax(250px,auto));
    margin: 0 3px;
  }
  }