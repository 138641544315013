$primaryColor:#E66294;

.meetTheTeam{
    width: 300px;
    height: 450px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    padding-bottom: 30px;
    box-shadow: 11px 20px 40px rgba(0, 0, 0, 0.377);
    // padding-left: 0;

    .imageDiv{
        border-radius: 50%;
        // padding: 30px;
        height: 250px;
        width: 250px;
        // padding-top: 10px;
        margin-top: 25px;
    //    display: flex;
    //    justify-content: center;

    }
    p{
        text-align: center;
        font-weight: bold;
    }
    p:nth-of-type(2){
        padding: 10px 20px;
    }
    a{
        text-decoration: none;
        display: block;
        margin: 10px 0;
        // color: black;
        &:hover{
            color:$primaryColor;
        }
        
    }
}

.imageDiv{
    // margin-top: 30px;
    // margin-left: 25px;
}

 @media only screen and(max-width:690px){
     .meetTheTeam{
         margin: 0 40px;
         width: auto;
     }
     .imageDiv{
        //  height: 150px;
        //  width: 150px;
        //  border-radius: 50%;
        // display: flex;
        // justify-content: center;
     }
 }


 @media only screen and(max-width:420px){
     .meetTheTeam{
         margin: 0 3px;
         width: auto;
     }
     .imageDiv{
        //  height: 150px;
        //  width: 150px;
        //  border-radius: 50%;
        // display: flex;
        // justify-content: center;
     }
 }