
$primaryColor:#E66294;

.homeBanner{
    display: grid;
    height: 100vh;
    grid-template-columns: 1.5fr 1fr;
}


.homeText{
// .mintDisplay{
//     color: white;
//     padding: 10px;
//     background-color: black;
//     border-radius: 10px;
//     font-weight: bold;
//     font-size: 1rem;
//     align-self: flex-start;
// }
    button{
         color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: $primaryColor;
    font-weight: bold;

    }
    align-self: center;
    padding-left: 100px;
    margin-left: 20px;
    // align-items: center;
    // display: block;
    // width: 100%;
    // justify-items: end;
    p{
        font-size: 40px;
        font-weight: bold;
        line-height: 40px;
        // text-align: end;

        // justify-self: end;

        span{
            font-size: 30px;
            // line-height: 10px;
        }
    }
}

@media only screen and(max-width:1010px){

    .homeBanner{
display: flex;
flex-direction: column;
    }
  .image{
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // position: unset;
    // left: 0%;
    // display: grid;
    // flex-direction: column;
    // grid-template-columns: 1fr;
    // left: unset;
    // width: 50%;
    position: relative;
    top: 0;
    left: 0;
    height: 100vh;
  }

  .homeText{

    button{
        background-color: black;
    }
      position: absolute;
      z-index: 30;
      top: 50%;
      padding-left: 0;
      margin-left: 0;
      left: 10%;
      transform: translateY(-50%);

     

      p{
          color: white;
      }

  }
}

@media only screen and(max-width:600px){
.homeText{
    button{
        display: none;
    }
}
}

@media only screen and(max-width:500px){
    .homeText{
        left: 5%;
    }
}