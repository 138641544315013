$primaryColor:#E66294;

.footerNavigation {
  display: flex;
  flex-direction: row;
  // margin-right:6px;
//   padding-left: -20px;

  list-style: none;
  align-items: center;
  justify-content: center;
  margin-left: 20px;

  a {
    padding-right: 20px;
    svg {
      width: 15px;
      height: 15px;
      color: white;
      // font-size: 100px;

      &:hover{
        color: $primaryColor;
      }
    }
  }
}
