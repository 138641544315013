.errorModal {
  background-color: rgb(207, 41, 41);
  position: fixed;
  top: 2%;
  width: 40%;
  left: 30%;
  border-radius: 10px;
  z-index: 800;
  padding: 16px;
  transition: all 0.3s ease-out;
  p{
      color: white;
      text-align: center;

      a{
          color: black;
      }
  }

  button{
      outline: none;
      border: none;
        background-color: transparent;
        cursor: pointer;
        width: 25px;
        height: 25px;
        position: absolute;
        top: 20%;
        right: 2%;

        svg{
            color: white;
            width: 100%;
            height: 100%;
        }
  }
}
