.frequentlyAskedQuestions{
    border: 4px solid black;
    border-radius: 20px;
    padding: 25px;
    margin-bottom: 30px;


    .faqTitle{
        font-weight: bold;
        font-size: larger;
    }
}

@media only screen and (max-width:420px){
    .frequentlyAskedQuestions{
        padding: 15px;
        border: 3px solid black;
    }
}