$primaryColor: #e66294;
$accentColor: #707070;
$progressBarColor: rgb(182, 182, 182);


.mintScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: $accentColor;
    // font-weight: bold;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0 0;
    font-size: 15px;
  }

  p:nth-of-type(1) {
    font-weight: bold;
    color: black;
    font-size: 20px;
  }

  .incOrDecNoOfMorans {
      margin-top: 15px;
    display: flex;
    flex-direction: row;

    p {
      //   color: green;
      margin: 0 10px;
    }

    button {
      display: block;
      outline: none;
      border: none;
      padding: 10px;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      background-color: $accentColor;
      cursor: pointer;

      svg {
        color: white;
        width: 100%;
        height: 100%;
        font-weight: bold;
      }

      &:hover {
        background-color: $primaryColor;
      }
    }
  }
}

.mintAndCancelButtons {
  position: absolute;
  bottom: 10px;
  right: 10px;

  button {
    margin-right: 15px;
    // color: red;
    outline: none;
    padding: 8px 20px;
    border-radius: 40px;
    cursor: pointer;
  }

  button:nth-of-type(1) {
    background-color: $accentColor;
    color: white;
    border: none;
    border: 3px solid $accentColor;

    &:hover {
      background-color: $primaryColor;
      border: 3px solid $primaryColor;
    }
  }
  button:nth-of-type(2) {
    background-color: white;
    color: $accentColor;
    border: 3px solid $accentColor;

    &:hover {
      background-color: $accentColor;
      color: white;
    }
  }
}


.progressBar{
  font-size: 13px;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 80%;
  counter-reset:step;
  // padding-bottom: ;
}

.progressBar::before{
  content: "";
  height: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: $progressBarColor;
  // background-color: $primaryColor;
  width: 100%;
  z-index: -1;
}

.progressBar25{
  font-size: 13px;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 80%;
  counter-reset:step;
  // padding-bottom: ;
}

.progressBar25::before{
  content: "";
  height: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  // background-color: $progressBarColor;
  background-color: $primaryColor;
  width: 33.33%;
  z-index: -2;
}
.progressBar50{
  font-size: 13px;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 80%;
  counter-reset:step;
  // padding-bottom: ;
}

.progressBar50::before{
  content: "";
  height: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  // background-color: $progressBarColor;
  background-color: $primaryColor;
  width: 66.66%;
  z-index: -2;
}
.progressBar100{
  font-size: 13px;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 80%;
  counter-reset:step;
  // padding-bottom: ;
}

.progressBar100::before{
  content: "";
  height: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  // background-color: $progressBarColor;
  background-color: $primaryColor;
  width: 100%;
  z-index: -2;
}

.progressStep{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $progressBarColor;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressStep::before{
  counter-increment: step;
  content: counter(step);
}

.progressStep::after{
  content: attr(data-title);
  position: absolute;
  top: calc(100% + 0.25rem);
  font-size: 13px;
  color: #707070;

}

///////////////////////////////////////


.progressStepActive{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $primaryColor;///background color of the step
  color: white;///color of the step color
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressStepActive::before{
  counter-increment: step;
  content: counter(step);
}

.progressStepActive::after{
  content: attr(data-title);
  position: absolute;
  top: calc(100% + 0.25rem);
  font-size: 13px;
  color: #707070;

}

.mintingProgressInfo{
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $accentColor;
}

.mintingProgressButton{
  button{
    position: absolute;
    bottom: 10px;
    right: 10px;
    outline: none;
    border: none;
    padding: 8px 20px;
    border-radius: 40px;
    color: black;
    cursor: pointer;
    background-color: $progressBarColor;

    &:hover{
      background-color: $primaryColor;
      color: white;
    }
  }
}

// .spinner{
  // height: 40px;
  // width: 40px;
  // margin-bottom: 20px;
// }