.about{
    align-self: center;
    padding: 50px 20px;
    h1{
        text-align: center;
    }
    p{
        margin-bottom: 30px;
        line-height: 30px;
        font-size: 1.2rem;
        a{
            color: rgb(245, 107, 130);
            text-decoration: none;
        }
    }
}

@media only screen and(max-width:1010px){
    .about{

        // padding: 50px 80px;
        margin: 0 40px;
        width: auto;
    }

}

 @media only screen and(max-width:690px){
  .about{
   padding: 30px 0;
   p{
       font-size: 1rem;
   }

    
    
  }
  }

  @media only screen and(max-width:550px){
  .about{
    margin: 0 20px;
  }
  }


  @media only screen and(max-width:420px){
  .about{
   padding: 10px 0 10px 0;
//    margin: 0 0 0 10px;
  }
  }