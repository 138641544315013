.modal{
    background-color: white;
    position: fixed;
    top: 20%;
    width:50%;
    left: 25%;
    border-radius: 15px;
    z-index: 450;
    transition: all 0.5s ease-out;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.466);
    padding: 16px;
}